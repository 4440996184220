import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Chip,
  Link,
  Box,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import * as OrdersSelectors from "../../store/Orders/selectors";
import * as AuthSelector from "../../store/Auth/selectors";
import {
  addComment,
  getComments,
  getManagers,
  getOrdersItem,
  getOrdersStatuses,
  setManager,
  setOrdersItem,
  updateOrdersItem,
} from "../../store/Orders/actions";
import { useState } from "react";
import moment from "moment";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const Order = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");

  const ordersItem = useSelector(OrdersSelectors.ordersItem);
  const ordersStatuses = useSelector(OrdersSelectors.ordersStatuses);
  const manager = useSelector(OrdersSelectors.manager);
  const comments = useSelector(OrdersSelectors.comments);

  const roles = useSelector(AuthSelector.userRole);

  const [manager_id, setManager_id] = useState(0);

  useEffect(() => {
    dispatch(getOrdersItem(id));
    dispatch(getComments(id));
    dispatch(getManagers());

    return () => {
      dispatch(setOrdersItem(null));
    };
  }, []);

  useEffect(() => {
    if (ordersStatuses.length === 0) {
      dispatch(getOrdersStatuses());
    }
  }, [ordersStatuses]);

  useEffect(() => {
    if (ordersItem) {
      if (comment === "" && status === "") {
        setComment(ordersItem.admin_comment);
        setStatus(ordersItem.status_id);
      }
      if (ordersItem.manager_id !== null) setManager_id(ordersItem.manager_id);
    }
  }, [ordersItem]);

  const adminAccess = () => {
    console.log(roles);
    const admin = roles.indexOf(15);
    if (admin === -1) return false;
    return true;
  };

  const getChip = () => {
    let color;

    switch (ordersItem.status_id) {
      case 1:
        color = "warning";
        break;
      case 2:
        color = "info";
        break;
      case 3:
        color = "info";
        break;
      case 4:
        color = "info";
        break;
      case 5:
        color = "success";
        break;
      case 8:
        color = "error";
        break;
      case 7:
        color = "error";
        break;
      default:
        color = "info";
        break;
    }

    return <Chip label={ordersItem.status} color={color} sx={{ ml: 2 }} />;
  };

  const TopOrder = () => {
    return (
      <Box>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            navigate("/");
          }}
        >
          Назад
        </Link>
        <Typography variant="h3" component="h2" sx={{ mb: 2 }}>
          Заявка №{ordersItem.id}
          {getChip()}
        </Typography>
      </Box>
    );
  };

  const TableList = () => {
    return (
      <>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Деталь</TableCell>
                <TableCell>Цена</TableCell>
                <TableCell>Кол-во</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Артикул 1С</TableCell>
                <TableCell>Машина</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersItem !== null &&
                ordersItem.items.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell>{row.price * row.count}</TableCell>
                    <TableCell>{row.article}</TableCell>
                    <TableCell>{row.machine_nomer}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={4}>Сумма заказа</TableCell>
                <TableCell colSpan={3}>{ordersItem.sum}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const Customer = () => {
    return (
      <Box>
        {ordersItem.company ? (
          <>
            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
              Компания
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography>{ordersItem.company.title}</Typography>
              <Typography>ИНН — {ordersItem.company.inn}</Typography>
              <Typography>КПП — {ordersItem.company.kpp}</Typography>
              <Typography>{ordersItem.company.address}</Typography>
            </Box>
          </>
        ) : null}
        {ordersItem.user ? (
          <>
            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
              Контактное лицо
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography>{ordersItem.user.name}</Typography>
              <Typography>{ordersItem.user.phone}</Typography>
              <Typography>{ordersItem.user.email}</Typography>
            </Box>
          </>
        ) : null}
        {ordersItem.comment ? (
          <>
            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
              Комментарий заказчика
            </Typography>
            <Box>
              <Typography>{ordersItem.comment}</Typography>
            </Box>
          </>
        ) : null}
        {ordersItem.manager_id === null || adminAccess() ? (
          <>
            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
              Менеджер
            </Typography>
            <Box>
              <TextField
                id="select-currency-native"
                margin="dense"
                select
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                size="small"
                onChange={(e) => setManager_id(e.target.value)}
                value={manager_id}
              >
                {ordersItem.manager_id === null && (
                  <option value={0}>Выбрать</option>
                )}
                {typeof manager !== "undefined" &&
                  manager !== null &&
                  manager.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </TextField>
            </Box>
            <Button
              variant="contained"
              onClick={() => dispatch(setManager(id, manager_id))}
            >
              Назначить
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
              Менеджер:
            </Typography>
            <Box>
              <Typography>{ordersItem.manager.name}</Typography>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const sendStatus = () => {
    dispatch(updateOrdersItem(ordersItem.id, status));
  };

  if (ordersItem === null) return null;

  return (
    <>
      <TopOrder />
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <TableList />
            <Box sx={{ mb: 2 }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Статус заказа
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  {ordersStatuses.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      control={<Radio />}
                      label={item.title}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                onClick={() => sendStatus()}
                variant="contained"
                sx={{
                  mb: "24px",
                }}
              >
                Сохранить
              </Button>
              <Box>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                >
                  {typeof comments !== "undefined" &&
                    comments !== null &&
                    comments.map((item) => (
                      <>
                        <ListItem alignItems="flex-start" key={item.id}>
                          <ListItemAvatar>
                            <AccountCircleOutlinedIcon
                              sx={{ color: "#1565c0", fontSize: "40px" }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.user.name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {item.comment}
                                </Typography>
                                <Box
                                  sx={{
                                    mt: "10px",
                                    fontSize: "12px",
                                    textAlign: "right",
                                  }}
                                >
                                  Дата:{" "}
                                  {moment(item.created_at).format(
                                    "DD.MM.YYYY HH:mm"
                                  )}
                                </Box>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    ))}
                </List>
              </Box>
              <TextField
                id="outlined-basic-c"
                onChange={(event) => setComment(event.target.value)}
                multiline
                sx={{ width: "100%", mb: 2, mt: 2 }}
                label="Комментарий"
                value={comment}
                variant="outlined"
              />
              <Button
                onClick={() => {
                  dispatch(addComment(id, comment));
                  setComment("");
                }}
                type="submit"
              >
                Добавить
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Customer />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Order;
