import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, TextField, Input, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { IMaskInput } from 'react-imask';
import * as OrdersSelectors from "../../store/Orders/selectors"
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { sendMail, sendMailState } from '../../store/Orders/actions';

const TextMaskPhone = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
})

const TextMaskInn = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="000000000000"
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
})

const TextMaskNomer = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="00000000"
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
})

function AddOrderForm({ open, setOpen }) {
    const formStatus = useSelector(OrdersSelectors.formStatus)

    const [nomer, setNomer] = useState(null)
    const [inn, setInn] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [psm, setPSM] = useState(null);
    const [sved, setSved] = useState(null);
    const [spis, setSpis] = useState(null);
    const [comment, setComment] = useState('')

    const dispatch = useDispatch()

    const handleClose = () => {
        setOpen(false)
        dispatch(sendMailState(null))
        setNomer(null)
        setInn(null)
        setPhone(null)
        setPSM(null)
        setSved(null)
        setSpis(null)
        setComment('')
        setEmail(null)
    }

    const handleSend = () => {
        const data = {
            nomer,
            inn,
            phone,
            email,
            psm,
            sved
        };

        let err = false
        Object.entries(data).forEach(([key, value]) => {
            if (value === null) err = true
        });

        if (err) {
            toast.error('Необходимо заполнить все поля с *')
            return false
        }

        data['spis'] = spis
        data['comment'] = comment

        //console.log(data)

        dispatch(sendMail(data))

        //setOpen(false)
    }

    useEffect(() => {
        if (formStatus === 'success') {
            setTimeout(() => {
                dispatch(sendMailState(null))
                setNomer(null)
                setInn(null)
                setPhone(null)
                setEmail(null)
                setPSM(null)
                setSved(null)
                setSpis(null)
                setComment('')
            }, 1500);
            //setOpen(false)
        }
    }, [formStatus])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Заказ запчастей на технику производства ДСТ Урал
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    (formStatus === 'load') ? (
                        <Box sx={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: '30px',
                            minWidth: '550px'
                        }}>
                            <div className="cssload-container">
                                <div className="cssload-speeding-wheel"></div>
                            </div>
                            <Box sx={{ textAlign: 'center', fontSize: '20px' }}>Отправка формы, не закрывайте страницу</Box>
                        </Box>
                    ) : ((formStatus === 'success') ? (
                        <Box sx={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: '30px',
                            minWidth: '550px'
                        }}>
                            <CheckCircleTwoToneIcon sx={{ color: '#2e7d32' }} />
                            <Box sx={{ textAlign: 'center', fontSize: '20px' }}>Успешно отправлено</Box>
                        </Box>
                    ) : ((formStatus === 'error') ? (
                        <Box sx={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: '30px',
                            minWidth: '550px'
                        }}>
                            <ErrorTwoToneIcon sx={{ color: '#d32f2f' }} />
                            <Box sx={{ textAlign: 'center', fontSize: '20px' }}>Не удалось отправить форму</Box>
                        </Box>
                    ) : (
                        <>
                            <TextField
                                fullWidth
                                required
                                label="Номер машины"
                                variant="outlined"
                                margin="dense"
                                value={nomer}
                                InputProps={{
                                    inputComponent: TextMaskNomer,
                                }}
                                onChange={e => setNomer(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                required
                                label="ИНН"
                                variant="outlined"
                                margin="dense"
                                value={inn}
                                InputProps={{
                                    inputComponent: TextMaskInn,
                                }}
                                onChange={e => setInn(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                required
                                label="Телефон"
                                variant="outlined"
                                margin="dense"
                                value={phone}
                                InputProps={{
                                    inputComponent: TextMaskPhone,
                                }}
                                onChange={e => setPhone(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                variant="outlined"
                                margin="dense"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Box sx={{
                                mt: '15px',
                                mb: '10px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button variant="contained" component="label" sx={{
                                    width: '60%'
                                }}>
                                    ПСМ * {psm ? '- ' + psm.name : null}
                                    <input hidden type="file" onChange={e => setPSM(e.target.files[0])} />
                                </Button>
                            </Box>
                            <Box sx={{
                                mt: '8px',
                                mb: '10px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button variant="contained" component="label" sx={{
                                    textAlign: 'center',
                                    width: '60%'
                                }}>
                                    Свидетельство о регистрации<br /> машины(трактора) * {sved ? '- ' + sved.name : null}
                                    <input hidden type="file" onChange={e => setSved(e.target.files[0])} />
                                </Button>
                            </Box>
                            <Box sx={{
                                mt: '8px',
                                mb: '15px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button variant="contained" component="label" sx={{
                                    width: '60%'
                                }}>
                                    Список запчастей {spis ? '- ' + spis.name : null}
                                    <input hidden type="file" onChange={e => setSpis(e.target.files[0])} />
                                </Button>
                            </Box>
                            <TextField
                                fullWidth
                                multiline
                                label="Комментарий"
                                variant="outlined"
                                margin="dense"
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                            />
                        </>
                    )))
                }
            </DialogContent>
            <DialogActions>
                {
                    formStatus === null ? <Button variant="contained" onClick={handleSend}>Отправить заказ</Button> : null
                }
            </DialogActions>
        </Dialog >
    )
}

export default AddOrderForm