import { SET_EMAILS } from "./types";

const initialState = {
    emails: []
}

export const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EMAILS:
            return { ...state, emails: action.payload }
        default:
            return state
    }
}