import {
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getOrdersList,
  getOrdersStatuses,
  setOrdersList,
  setOrdersStatus,
} from "../../store/Orders/actions";
import * as OrdersSelectors from "../../store/Orders/selectors";
import moment from "moment/moment";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(1);

  const ordersStatuses = useSelector(OrdersSelectors.ordersStatuses);
  const ordersStatus = useSelector(OrdersSelectors.ordersStatus);
  const ordersList = useSelector(OrdersSelectors.ordersList);

  const ref = useRef();

  const handlePage = (page) => {
    dispatch(setOrdersList([]));
    setPage(page);
    dispatch(getOrdersList(ordersStatus, page));
    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    navigate(
      `${location.pathname}?page=${page}${
        ordersStatus !== null && ordersStatus !== "full"
          ? `&status=${ordersStatus}`
          : ``
      }`
    );
  };

  useEffect(() => {
    dispatch(getOrdersStatuses());
    const query = new URLSearchParams(location.search);
    const qPage = query.get("page") !== null ? query.get("page") : 1;
    setPage(parseInt(qPage));
  }, []);

  useEffect(() => {
    if (typeof ordersStatuses === "undefined" || ordersStatuses.length === 0)
      return;

    const query = new URLSearchParams(location.search);
    const status =
      query.get("status") !== null && !isNaN(parseInt(query.get("status")))
        ? parseInt(query.get("status"))
        : "full";

    if (status !== null && status !== "full")
      ordersStatuses.map((item) => {
        if (item.id === status) dispatch(setOrdersStatus(status));
      });
    else dispatch(setOrdersStatus("full"));
  }, [ordersStatuses]);

  useEffect(() => {
    if (ordersStatus === null) return;

    dispatch(getOrdersList(ordersStatus, page));
  }, [ordersStatus]);

  const getMenuItem = (item) => {
    if (item.id === ordersStatus)
      return (
        <MenuItem key={item.id} selected={true}>
          <ListItemText>{item.title}</ListItemText>
        </MenuItem>
      );
    return (
      <MenuItem
        key={item.id}
        selected={false}
        component={Link}
        href={`?status=${item.id}`}
      >
        <ListItemText>{item.title}</ListItemText>
      </MenuItem>
    );
  };

  const getBorderColor = (statusId) => {
    switch (statusId) {
      case 2:
        return "#ace301";
      case 3:
        return "#242ec5";
      case 6:
        return "#00C734";
      case 7:
        return "#eb050e";
      case 8:
        return "#FF7F02";
      default:
        return "#ffffff";
    }
  };

  const List = () => {
    return (
      <>
        <TableContainer ref={ref} component={Paper} sx={{ mb: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Заказ</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Заказчик</TableCell>
                {ordersStatus === "full" ? (
                  <TableCell>Статус заказа</TableCell>
                ) : null}
                <TableCell>Компания</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeof ordersList !== "undefined" &&
              ordersList.list &&
              ordersList.list.length
                ? ordersList.list.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="td" scope="row">
                        <a href={`/order/${row.id}`}>{row.id}</a>
                      </TableCell>
                      <TableCell>{row.sum}</TableCell>
                      <TableCell>
                        {moment(row.created_at).format("DD.MM.YYYY HH:mm")}
                      </TableCell>
                      <TableCell>{row.user && row.user.name}</TableCell>
                      {ordersStatus === "full" ? (
                        <TableCell>
                          <Box
                            sx={{
                              border: `1px solid ${getBorderColor(
                                row.status_id
                              )}`,
                              borderRadius: "5px",
                              padding: "3px",
                              textAlign: "center",
                            }}
                          >
                            {row.status}
                          </Box>
                        </TableCell>
                      ) : null}
                      <TableCell>{row.company && row.company.title}</TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          sx={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
          count={
            typeof ordersList.last_page != "undefined"
              ? ordersList.last_page
              : 0
          }
          page={page}
          onChange={(event, page) => handlePage(page)}
        />
      </>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper sx={{ width: 320, maxWidth: "100%", mb: 3 }}>
            <MenuList>
              <MenuItem
                key={0}
                selected={ordersStatus === null}
                component={Link}
                href="/"
              >
                <ListItemText>Все заказы</ListItemText>
              </MenuItem>
              {ordersStatuses.map((item) => getMenuItem(item))}
            </MenuList>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <List />
        </Grid>
      </Grid>
    </>
  );
};

export default Orders;
