import {
  SET_COMMENTS,
  SET_FORM_STATE,
  SET_MAIL_ORDER,
  SET_MAIL_ORDERS_LIST,
  SET_MANAGER,
  SET_ORDERS_ITEM,
  SET_ORDERS_LIST,
  SET_ORDERS_STATUS,
  SET_ORDERS_STATUSES,
} from "./types";

const initialState = {
  ordersStatuses: [],
  ordersStatus: null,
  ordersList: [],
  ordersItem: null,
  mailOrderList: [],
  mailOrder: [],
  manager: [],
  comments: [],
  formStatus: null,
};

export const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS_STATUSES:
      return { ...state, ordersStatuses: action.payload };
    case SET_ORDERS_STATUS:
      return { ...state, ordersStatus: action.payload };
    case SET_ORDERS_LIST:
      return { ...state, ordersList: action.payload };
    case SET_MAIL_ORDERS_LIST:
      return { ...state, mailOrderList: action.payload };
    case SET_MAIL_ORDER:
      return { ...state, mailOrder: action.payload };
    case SET_ORDERS_ITEM:
      return { ...state, ordersItem: action.payload };
    case SET_MANAGER:
      return { ...state, manager: action.payload };
    case SET_COMMENTS:
      return { ...state, comments: action.payload };
    case SET_FORM_STATE:
      return { ...state, formStatus: action.payload };
    default:
      return state;
  }
};
