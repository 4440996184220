import { Box, List, ListItem, ListItemText, Typography, Divider, TextField, Button, IconButton } from '@mui/material'
import React, { useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMail, destroyEmail, getEmails } from '../../store/Settings/actions'
import * as SettingsSelectors from '../../store/Settings/selectors'

function Settings() {
    const dispatch = useDispatch()

    const emails = useSelector(SettingsSelectors.emails)

    const [mail, setMail] = useState('')

    useEffect(() => {
        dispatch(getEmails())
    }, [])

    const handleaddMail = () => {
        if (mail !== '') {
            dispatch(addMail(mail))
            setMail('')
        }
    }

    return (
        <Box sx={{ display: "flex" }}>
            <Box width={300} sx={{
                boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                p: '20px',
                borderRadius: '4px',
                mb: 2,
                mr: 2
            }}>
                <Typography sx={{ fontWeight: '500', mb: 2 }} component="h3">Уведомлять о новом заказе из "формы заказа" на почту</Typography>
                <Divider />
                <List>
                    {
                        emails !== [] && emails.map(item => (
                            <ListItem key={item.id}>
                                <ListItemText primary={item.email} />
                                <IconButton color='error' onClick={() => dispatch(destroyEmail(item.id))}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </ListItem>
                        ))
                    }
                </List>
                <Divider />
                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                    />
                    <Button onClick={handleaddMail} variant="contained" sx={{ mt: 2 }}>Добавить</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Settings