import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import { AuthReducer } from "./Auth/reducers"
import { OrdersReducer } from "./Orders/reducers"
import { SettingsReducer } from "./Settings/reducers"

const rootReducers = combineReducers({
    AuthReducer,
    OrdersReducer,
    SettingsReducer
})

export default createStore(rootReducers, applyMiddleware(thunk))