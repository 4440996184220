import { toast } from "react-toastify";
import api from "../../services/api";

import { SET_EMAILS } from "./types";

export const setEmails = data => {
    return {
        type: SET_EMAILS,
        payload: data
    }
}

export const getEmails = () => async (dispatch, getState) => {

    try {
        const response = await api.get(`/api/v1/shop/emails`)

        if (response.status === 200) {
            const json = await response.data

            dispatch(setEmails(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const destroyEmail = (id) => async (dispatch, getState) => {

    try {
        const response = await api.delete(`/api/v1/shop/emails/${id}`)

        if (response.status === 200) {
            toast.success('Почта успешно удалена')
            dispatch(getEmails())
        }

    } catch (e) {
        console.error(e)
    }
}

export const addMail = (email) => async (dispatch, getState) => {
    try {
        const response = await api.post(`/api/v1/shop/emails`, {
            email
        })

        if (response.status === 201) {
            toast.success('Почта успешно добавлена')
            dispatch(getEmails())
        }

    } catch (e) {
        console.error(e)
    }
}