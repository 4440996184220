import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components";
import { Auth, Home, MailOrder, MailOrderDetail, Orders, Settings } from "./screens";
import { getUser, setAuth } from "./store/Auth/actions";
import * as AuthSelector from "./store/Auth/selectors"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Order from "./screens/Orders/Order";


function App() {
    const auth = useSelector(AuthSelector.auth)
    const token = useSelector(AuthSelector.token)

    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            dispatch(getUser())
        } else {
            dispatch(setAuth(false))
        }
    }, [auth])

    if (auth === null) return <></>

    return (
        <Wrapper>
            {
                auth ? <Routes>
                    <Route path="/" element={<Orders />} />
                    <Route path="/order/:id" element={<Order />} />
                    <Route path="/mailorder" element={<MailOrder />} />
                    <Route path="/mailorder/:id" element={<MailOrderDetail />} />
                    <Route path="/settings" element={<Settings />} />
                </Routes> : <Auth />
            }
            <ToastContainer
                position="bottom-right"
            />
        </Wrapper>
    )
}

export default App;
