import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMailOrder, getMailOrderUpdate } from "../../store/Orders/actions";
import * as OrdersSelectors from "../../store/Orders/selectors";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";

function MailOrderDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mailOrder = useSelector(OrdersSelectors.mailOrder);

  useEffect(() => {
    dispatch(getMailOrder(id));
  }, [id]);

  /*     useEffect(() => {
            if (mailOrder.user_id !== null)
                dispatch()
        }, [mailOrder.user_id]) */

  const TopOrder = () => {
    return (
      <Box>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Link>
        <Typography variant="h3" component="h2" sx={{ mb: 2 }}>
          Заявка из формы заказа №{id}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <TopOrder />
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Номер</TableCell>
                  <TableCell>{mailOrder.nomer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ИНН</TableCell>
                  <TableCell>{mailOrder.inn}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Телефон</TableCell>
                  <TableCell>{mailOrder.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Комментарий</TableCell>
                  <TableCell>{mailOrder.comment}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Дата/Время оформления заказа</TableCell>
                  <TableCell>
                    {moment(mailOrder.created_at).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={5}>
          <TableContainer>
            <Table>
              <TableBody>
                {mailOrder.psm ? (
                  <TableRow>
                    <TableCell>ПСМ</TableCell>
                    <TableCell>
                      <a
                        href={`http://shop.dstapi.ru/${mailOrder.psm}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {mailOrder.psm.split(".").pop() === "png" ||
                        mailOrder.psm.split(".").pop() === "jpg" ||
                        mailOrder.psm.split(".").pop() === "jpeg" ||
                        mailOrder.psm.split(".").pop() === "heif" ? (
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                            }}
                            src={`http://shop.dstapi.ru/${mailOrder.psm}`}
                            alt=""
                          />
                        ) : (
                          <DescriptionIcon sx={{ color: "#5955b3" }} />
                        )}
                      </a>
                    </TableCell>
                  </TableRow>
                ) : null}
                {mailOrder.psm ? (
                  <TableRow>
                    <TableCell>
                      Сведетельство о регистрации машины(трактора)
                    </TableCell>
                    <TableCell>
                      <a
                        href={`http://shop.dstapi.ru/${mailOrder.sved}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {mailOrder.psm.split(".").pop() === "png" ||
                        mailOrder.psm.split(".").pop() === "jpg" ||
                        mailOrder.psm.split(".").pop() === "jpeg" ||
                        mailOrder.psm.split(".").pop() === "heif" ? (
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                            }}
                            src={`http://shop.dstapi.ru/${mailOrder.sved}`}
                            alt=""
                          />
                        ) : (
                          <DescriptionIcon sx={{ color: "#5955b3" }} />
                        )}
                      </a>
                    </TableCell>
                  </TableRow>
                ) : null}
                {mailOrder.spis ? (
                  <TableRow>
                    <TableCell>Список запчастей</TableCell>
                    <TableCell>
                      <a
                        href={`http://shop.dstapi.ru/${mailOrder.spis}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {mailOrder.psm.split(".").pop() === "png" ||
                        mailOrder.psm.split(".").pop() === "jpg" ||
                        mailOrder.psm.split(".").pop() === "jpeg" ||
                        mailOrder.psm.split(".").pop() === "heif" ? (
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                            }}
                            src={`http://shop.dstapi.ru/${mailOrder.spis}`}
                            alt=""
                          />
                        ) : (
                          <DescriptionIcon sx={{ color: "#5955b3" }} />
                        )}
                      </a>
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell>
                    {mailOrder.user_id === null ? (
                      <Button
                        onClick={() =>
                          dispatch(getMailOrderUpdate(mailOrder.id))
                        }
                        variant="contained"
                      >
                        Принять в работу
                      </Button>
                    ) : (
                      <p>
                        Заявка в работе. Ответственный:{" "}
                        {typeof mailOrder.user_id !== "undefined"
                          ? mailOrder.user_id.name
                          : ""}
                      </p>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MailOrderDetail;
