import "./style.css"

const Preloader = () => {
    return (
        <div className="preloader">
            <div className="cssload-container">
                <div className="cssload-speeding-wheel"></div>
            </div>
        </div>
    )
}

export default Preloader