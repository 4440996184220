import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Preloader, Table } from "../../components";
import {
  getMailOrdersList,
  getManagers,
  setMailOrdersList,
} from "../../store/Orders/actions";
import * as OrdersSelectors from "../../store/Orders/selectors";
import { Box, Fab, Pagination, TextField } from "@mui/material";
import { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddOrderForm from "../../components/AddOrderForm";

function MailOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const mailOrderList = useSelector(OrdersSelectors.mailOrderList);
  const manager = useSelector(OrdersSelectors.manager);

  const [filter, setFilter] = useState(0);
  const [sort, setSort] = useState(0);

  const [page, setPage] = useState(1);
  const ref = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qPage =
      query.get("page") !== null && !isNaN(parseInt(query.get("page")))
        ? parseInt(query.get("page"))
        : 1;
    setPage(qPage);
    dispatch(getMailOrdersList(qPage));
    dispatch(getManagers());
  }, []);

  useEffect(() => {
    if (filter !== 0 || sort !== 0) {
      setPage(1);
      dispatch(getMailOrdersList(1, filter, sort));
    }
  }, [filter, sort]);

  const next = (id) => {
    navigate(`/mailorder/${id}`);
  };

  const user = (item) => {
    if (item.user_id) {
      return item.user.name;
    } else {
      return "Не назначен";
    }
  };

  const link = (item) => {
    return <a href={`/mailorder/${item.id}`}>{item.id}</a>;
  };

  const cols = [
    { title: "Заказ", val: link },
    { title: "Номер", code: "nomer" },
    { title: "ИНН", code: "inn" },
    { title: "Телефон", code: "phone" },
    {
      title: "Дата/Время оформления заказа",
      code: "created_at",
      type: "dateTime",
    },
    { title: "Ответственный", val: user },
  ];

  const handlePage = (page) => {
    setPage(page);
    dispatch(setMailOrdersList([]));
    dispatch(getMailOrdersList(page, filter, sort));
    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    //navigate(`${location.pathname}?page=${page}${type_id !== 0 ? '&type=' + type_id : ''}`)
    navigate(`${location.pathname}?page=${page}`);
  };

  if (mailOrderList.length === 0) return <Preloader />;
  return (
    <>
      {/* <Box>
                <TextField
                    label="Фильтр по менеджерам"
                    color="custom_purple"
                    margin="dense"
                    select
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    size='small'
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                >
                    <option value={0}>Выбрать</option>
                    {
                        manager !== [] && manager.map(item => <option value={item.id}>{item.name}</option>)
                    }
                </TextField>
                <TextField
                    sx={{
                        ml: 2
                    }}
                    label="Сортировка"
                    color="custom_purple"
                    margin="dense"
                    select
                    variant="outlined"
                    size='small'
                    SelectProps={{
                        native: true,
                    }}
                    value={sort}
                    onChange={e => setSort(e.target.value)}
                >
                    <option value={0}>Выбрать</option>
                    <option value="date_up">По дате добавления &#129045;</option>
                    <option value="date_down">По дате добавления &#129047;</option>
                    <option value="nomer_up">По номеру &#129045;</option>
                    <option value="nomer_down">По номеру &#129047;</option>
                </TextField>
            </Box> */}
      <Box
        sx={{
          flex: 1,
          overflowY: "scroll",
          height: "100%",
        }}
        ref={ref}
      >
        <Table
          cols={cols}
          data={typeof mailOrderList.data !== "undefined" && mailOrderList.data}
        />
        <Pagination
          sx={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
          count={
            typeof mailOrderList.last_page !== "undefined"
              ? mailOrderList.last_page
              : 0
          }
          page={page}
          shape="rounded"
          color="custom_purple"
          onChange={(event, page) => handlePage(page)}
        />
      </Box>
      <Fab
        color="primary"
        aria-label="add"
        color="custom_purple"
        sx={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
        }}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <AddOrderForm open={open} setOpen={setOpen} />
    </>
  );
}

export default MailOrder;
