import { toast } from "react-toastify";
import api from "../../services/api";

import {
  SET_COMMENTS,
  SET_FORM_STATE,
  SET_LOAD_DATA,
  SET_MAIL_ORDER,
  SET_MAIL_ORDERS_LIST,
  SET_MANAGER,
  SET_ORDERS_ITEM,
  SET_ORDERS_LIST,
  SET_ORDERS_STATUS,
  SET_ORDERS_STATUSES,
} from "./types";

export const setOrdersStatuses = (data) => {
  return {
    type: SET_ORDERS_STATUSES,
    payload: data,
  };
};

export const setOrdersStatus = (data) => {
  return {
    type: SET_ORDERS_STATUS,
    payload: data,
  };
};

export const setOrdersList = (data) => {
  return {
    type: SET_ORDERS_LIST,
    payload: data,
  };
};

export const setMailOrdersList = (data) => {
  return {
    type: SET_MAIL_ORDERS_LIST,
    payload: data,
  };
};

export const setMailOrder = (data) => {
  return {
    type: SET_MAIL_ORDER,
    payload: data,
  };
};

export const setOrdersItem = (data) => {
  return {
    type: SET_ORDERS_ITEM,
    payload: data,
  };
};

export const getOrdersStatuses = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/order/statuses`);

    if (response.status === 200) {
      const json = await response.data;

      //dispatch(setOrdersStatus(json[0].id));
      dispatch(setOrdersStatuses(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getOrdersList =
  (status = null, page = 0) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/v1/shop/orders?limit=15&page=${page}${
          status !== null && status !== "full" ? `&status=${status}` : ``
        }`
      );

      if (response.status === 200) {
        const json = await response.data;

        dispatch(setOrdersList(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getMailOrdersList =
  (page = 1, filter = 0, sort = 0) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(
        `/api/v1/shop/order/form?limit=40&page=${page}${
          filter !== 0 ? "&filter=" + filter : ""
        }`
      );

      if (response.status === 200) {
        const json = await response.data;

        dispatch(setMailOrdersList(json));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getMailOrder = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/order/form/${id}`);

    if (response.status === 200) {
      const json = await response.data;

      dispatch(setMailOrder(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getMailOrderUpdate = (id) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/v1/shop/order/form/${id}`);

    if (response.status === 200) {
      toast.success("Заявка успешно принята");
      dispatch(getMailOrder(id));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getOrdersItem = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/orders/${id}`);

    if (response.status === 200) {
      const json = await response.data;

      dispatch(setOrdersItem(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const updateOrdersItem = (id, status) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/v1/shop/order/${id}/status`, {
      status_id: status,
    });

    if (response.status === 200) {
      toast.success("Статус заказа успешно изменен");
      dispatch(getOrdersItem(id));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setManagers = (data) => {
  return {
    type: SET_MANAGER,
    payload: data,
  };
};

export const getManagers = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/company/1/users?role=19`);

    if (response.status === 200) {
      const json = await response.data;

      dispatch(setManagers(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setManager = (id, user_id) => async (dispatch, getState) => {
  try {
    const response = await api.put(`/api/v1/shop/order/${id}/manager`, {
      user_id,
    });

    if (response.status === 200) {
      const json = await response.data;

      toast.success("Менеджер успешно назначен");
      dispatch(getOrdersItem(id));
    }
  } catch (e) {
    console.error(e);
  }
};

export const setComments = (data) => {
  return {
    type: SET_COMMENTS,
    payload: data,
  };
};

export const getComments = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/order/${id}/comment`);

    if (response.status === 200) {
      const json = await response.data;

      dispatch(setComments(json));
    }
  } catch (e) {
    console.error(e);
  }
};

export const addComment = (id, comment) => async (dispatch, getState) => {
  try {
    const response = await api.post(`/api/v1/shop/order/${id}/comment`, {
      comment,
    });

    if (response.status === 201) {
      const json = await response.data;

      toast.success("Комментарий успешно добавлен");
      dispatch(getComments(id));
    }
  } catch (e) {
    console.error(e);
  }
};

export const sendMailState = (data) => {
  return {
    type: SET_FORM_STATE,
    payload: data,
  };
};

export const sendMail = (data) => async (dispatch, getState) => {
  try {
    dispatch(sendMailState("load"));
    let formData = new FormData();

    formData.append("nomer", data.nomer);
    formData.append("inn", data.inn);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("comment", data.comment);

    formData.append("psm", data.psm, data.psm.name);
    formData.append("sved", data.sved, data.sved.name);

    if (data.spis !== null) formData.append("spis", data.spis, data.spis.name);

    const response = await api.post(`/api/v1/shop/order/form`, formData);

    if (response.status === 201) {
      dispatch(sendMailState("success"));
      toast.success("Заявка создана");
    }
  } catch (e) {
    dispatch(sendMailState("error"));
    console.error(e);
  }
};
